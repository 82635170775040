










































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/PickupController'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import ModalSuccess from '@/app/ui/views/Pickup/components/Modals/ModalSuccess.vue'
import ModalConfirmation from '@/app/ui/views/Pickup/components/Modals/ModalConfirmation.vue'
import { PickupForceDetail } from '@/domain/entities/Pickup'
import { LocalStorage } from '@/app/infrastructures/misc'
import router from '@/app/ui/router'

@Component({
  components: {
    TextInput,
    Button,
    LoadingOverlay,
    ModalSuccess,
    ModalConfirmation
  },
})
export default class ForceCRRNFDPage extends Vue {
  controller = controller
  form = {
    actorId: '',
    taskId: 0,
    shipmentId: '',
    bookingId: '',
    nameCustomer: '',
    noTelp: '',
    address: '',
    courier: '',
    from: 'liberta',
    email: ''
  }
  modalConfirmation = false
  modalSuccess = false

  created(): void {
    this.form.actorId = LocalStorage.getLocalStorage(
      LocalStorage.ACCOUNT_ID,
      true
    )
    this.form.email = LocalStorage.getLocalStorage(
      LocalStorage.LOGIN_IDENTITY,
      true
    )
    this.fetchDetail()
  }

  get forceCRRNFDID(): string {
    return this.$route.params.shipmentId
  }

  get dataForceCRRNFD(): PickupForceDetail {
    return this.controller.pickupDetailForce
  }

  private fetchDetail() {
    controller.getPickupDetailForce({
      shipment_id: this.forceCRRNFDID,
    })
  }

  private parsePhoneNumber(phoneNumber: string) {
    if (phoneNumber.startsWith('0')) {
      return phoneNumber.slice(1, phoneNumber.length)
    } else {
      return phoneNumber
    }
  }

  private onCancel() {
    this.$router.push({ name: 'PickupCRROTW' })
  }

  private onCloseModalSuccess() {
    controller.setIsSuccessForce(false)
    router.push({
      name: 'PickupCRROTW',
      query: { refetch: 'true' }
    })
  }

  private onSubmitCRRNFD() {
    // ON SUBMIT
    this.controller.pickupDedicatedCRROTW({
      taskId: this.form.taskId,
      shipmentId: this.form.shipmentId,
      actorId: Number(this.form.actorId),
      email: this.form.email,
      from : this.form.from
    })
    this.modalConfirmation = false
  }

  @Watch('controller.isSuccessForce')
  onShowModalSuccess(val: boolean): void {
    this.modalConfirmation = false
    this.modalSuccess = val
  }

  @Watch('controller.pickupDetailForce')
  onChangeForm(val: PickupForceDetail): void {
    this.form = {
      ...this.form,
      taskId: val.taskId || 0,
      shipmentId: val.shipmentId || '',
      bookingId: val.bookingId || '',
      nameCustomer: val.receiverName || '',
      noTelp: this.parsePhoneNumber(val.receiverContact || ''),
      address: val.receiverAddress || '',
      courier: val.actorName || ''
    }
  }
}
